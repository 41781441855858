import { datadogRum } from "@datadog/browser-rum";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { GoogleOAuthProvider } from "@react-oauth/google";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import Head from "next/head";
import { useRouter } from "next/router";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React, { Fragment, useEffect } from "react";
import { hotjar } from "react-hotjar";
import { useDispatch } from "react-redux";
import Color from "../assets/colors";
import theme from "../assets/theme";
import * as config from "../config/config";
import { parseJWT } from "../helpers/auth.js";
import { STOP_SYNC_LOGIN } from "../helpers/constant.js";
import { getItem } from "../helpers/data_management";
import { sendTrackingEvent } from "../helpers/tracking_management";
import {
  updateShowSignInModalStatus,
  updateSignInModalSignUpStatus,
} from "../redux/actions/navbar_action";
import {
  checkAuthentication,
  checkUserSession,
  getUser,
  logout,
  newGetUserInfo,
  triggerSwitchCountry,
} from "../redux/actions/user_action";
import { wrapper } from "../redux/stores/store";
import * as types from "../redux/types/user_type";

let cleverTapInitRetryCount = 0;

// outside of react life cycle
if (typeof window !== "undefined") {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
    opt_in_site_apps: true,
    // Enable debug mode in development
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug();
    },
  });
}

function MyApp(props) {
  const { Component, pageProps, err } = props;
  const user = getUser();
  const router = useRouter();
  const dispatch = useDispatch();

  useEffect(() => {
    // HANDLE GLOBAL LOGIN - START
    const stopSyncLogin = Cookies.get(STOP_SYNC_LOGIN);
    const logoutFlag = Cookies.get(process.env.NEXT_PUBLIC_LOGOUT_FLAG);

    async function handleSwitchCountry() {
      const cookieGlobalId = Cookies.get(process.env.NEXT_PUBLIC_GLOBAL_ID);

      if (cookieGlobalId) {
        const doneSwitchCountry = await dispatch(
          triggerSwitchCountry({ globalId: cookieGlobalId ?? "" })
        );

        if (doneSwitchCountry?.success === true) {
          dispatch(newGetUserInfo());

          triggerCleverTap();

          Cookies.set(STOP_SYNC_LOGIN, true);
        }
      }
    }

    if (user) {
      checkUserSession().then((response) => {
        const userToken = getItem(types.USER_TOKEN);
        const decodedUserToken = parseJWT(userToken);

        const resetPasswordAt = dayjs(
          dayjs(response.resetPasswordAt).format("YYYY-MM-DD HH:mm:ss Z")
        );
        const tokenExpiryAt = dayjs(
          decodedUserToken.expiry_at,
          "YYYY-MM-DD HH:mm:ss Z"
        );
        const userLoginAt = dayjs(
          decodedUserToken.login_at,
          "YYYY-MM-DD HH:mm:ss Z"
        );

        if (resetPasswordAt) {
          const isTokenExpired = resetPasswordAt.isAfter(tokenExpiryAt);
          const isResetAfterLogin = resetPasswordAt.isAfter(userLoginAt);

          if (isTokenExpired || isResetAfterLogin) {
            return dispatch(logout()).then(() => {
              router.push("/");
            });
          }
        }
      });
      Cookies.set(STOP_SYNC_LOGIN, true);
    }

    if (logoutFlag) {
      return dispatch(logout());
    } else if (!stopSyncLogin || stopSyncLogin !== true) {
      // essentially we want to only sync once every
      handleSwitchCountry();
    }
    // HANDLE GLOBAL LOGIN - END
  }, []);

  // Show sign up dialog when specific params is present in URL
  if (router.asPath.includes("signup") && !checkAuthentication()) {
    dispatch(updateShowSignInModalStatus(true));
    dispatch(updateSignInModalSignUpStatus(true));
  }

  const triggerCleverTapWebPushNotifications = () => {
    try {
      // Grant permission from users to subscribe to CleverTap's web push notifications
      clevertap.notifications.push({
        // apnsWebPushId: "<apple web push id>", // Only for safari browser
        // apnsWebPushServiceUrl: "<safari package service url>", // Only for safari browser
        titleText: "Would you like to receive Push Notifications?",
        bodyText:
          "We promise to only send you relevant content and give you updates on your transactions",
        okButtonText: "Sign me up!",
        rejectButtonText: "No thanks",
        okButtonColor: Color.hiredlyPurple,
        serviceWorkerPath: "./clevertap_sw.js", // path to your custom service worker file
      });
    } catch (error) {
      console.error(error);
    }
  };

  const triggerCleverTap = () => {
    setTimeout(() => {
      try {
        clevertap.profile.push({
          Site: {
            Name: user.name ?? null, // String
            Identity: user.id ?? null, // String or number
            Email: user.email ?? null, // Email address of the user
            Phone: user.mobileNumber ?? null, // Phone (with the country code)
            Gender: user.gender === "male" ? "M" : "F", // Can be either M or F
            DOB: user.birthDate ? new Date(user.birthDate) : null, // Date of Birth. Date object
            SignUpPlatform: user.signupPlatformDeviceOs ?? null, // String
            HaveResume: user.uploadResumeAt ? true : false, // Boolean
            ResumeApproved: user.approved ? true : false, // Boolean
            ResumeApprovalDate: user.approved
              ? user.approve_at
                ? new Date(user.approveAt)
                : null
              : null, // Date object
            LastResumeUploadDate: user.uploadResumeAt
              ? new Date(user.uploadResumeAt)
              : null, // Date object
            RegistrationStartDate: user.createdAt
              ? new Date(user.createdAt)
              : null, // Date object
            JobTrack:
              user.trackIds &&
              Array.isArray(user.trackIds) &&
              user.trackIds?.length >= 1
                ? user.trackIds
                : null, // Arrays
            RecentJobTitle: user.current_position ?? null, // String
            RecentCompany: user.current_company ?? null, // String
            ShortSummary: user.short_summary ?? null, // String
          },
        });
      } catch (error) {
        console.error("clevertap_error:", error);

        if (error && cleverTapInitRetryCount < 6) {
          cleverTapInitRetryCount++;
          triggerCleverTap();
        }
      }
    }, 1000);
  };

  function initDatadogFunc() {
    // do not initialise datadog SDK if on local branches.
    if (process.env.NEXT_PUBLIC_DATADOG_ENV === "local") {
      return;
    }

    // CONTINUE IF ENVIRONMENT IS NOT LOCAL
    datadogRum.init({
      applicationId: process?.env?.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
      clientToken: process?.env?.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "hiredly-jsw",
      env: process?.env?.NEXT_PUBLIC_DATADOG_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: process?.env?.NEXT_PUBLIC_DATADOG_SESSION_RATE,
      sessionReplaySampleRate:
        process?.env?.NEXT_PUBLIC_DATADOG_SESSION_REPLAY_RATE,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
      allowedTracingUrls: [
        // NOTE: Don't use [/https:\/\/.*\.wobbjobs\.com/], it will auto inject Datadog Headers to Wordpress API call
        // as it also ends with "wobbjobs.com" also, which can cause CORS error
        config.baseURL,
        // /https:\/\/.*\.wobbjobs\.com/,
        "https://staging-my.wobbjobs.com",
        "https://api-v2.wobbjobs.com",
        /https:\/\/.*\.hiredly\.com/,
        (url) => url.startsWith(config.baseURL),
        process.env.NEXT_PUBLIC_APP_READONLY_API_URL,
      ],
    });

    datadogRum.startSessionReplayRecording();

    // Set user session info for Datadog RUM
    datadogRum.setUser({
      id: user?.id,
      email: user?.email,
      name: user?.name,
    });
  }

  function userInitPosthog() {
    posthog.identify(user?.id, {
      uuid: user.id,
      age: parseInt(user?.age ?? 0),
      gender: user?.gender,
      nationality: user?.nationality,
      is_actively_available: user?.activelyAvailable,
      has_resume: user?.resume ? true : false,
      current_location: user?.currentLocation,
      is_fresh_grad: user?.freshGrad,
      is_onboarding_completed: user?.onboardingComplete,
      signup_platform_device_os: user?.signupPlatformDeviceOs,
      track_ids: user?.trackIds,
      // year_of_experience: workExpYearDiff ?? 0,
      name: user?.name,
      email: user?.email,
    });
  }

  useEffect(() => {
    initDatadogFunc();

    if (user?.id && process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "my") {
      userInitPosthog();
      triggerCleverTap();

      // Only trigger CleverTap web push notifications on production
      if (process?.env?.NEXT_PUBLIC_JSW_ENV === "prod") {
        triggerCleverTapWebPushNotifications();
      }
    }
  }, [user]);

  useEffect(() => {
    if (user?.id) {
      Cookies.set("userId", user.id, { expires: 365 });
      sendTrackingEvent({
        event: "CE_auto-login",
      });
    }

    // Hotjar initialization code
    hotjar.initialize("2742152", "6");

    // Check if user is logged out and change cookie to false for CleverTap
    Cookies.set("isRegistered", user?.id ? true : false, { expires: 365 });

    //clevertap initialization code
    if (typeof window !== "undefined") {
      clevertap = require("clevertap-web-sdk");
      clevertap.init(process.env?.NEXT_PUBLIC_CLEVERTAP, "sg1");
      clevertap.setLogLevel(3);
    }

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // Check UTM params when page initially loads to store into cookies for ads sign up tracking
    const params = new URL(location.href).searchParams;
    const utm_medium = params.get("utm_medium");
    const URLparams = window?.location.search.substr(1);

    if (utm_medium?.length > 0) {
      Cookies.set("params", URLparams, { expires: 7 });
    } else if (document.cookie.indexOf("params") != -1) {
    }

    const handleRouteChange = () => posthog.capture("$pageview");

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <Fragment>
      <Head>
        {/* Favicon */}
        <link
          rel="icon"
          href={config.assetDomain + "/images/hiredly/hiredly-favicon.png"}
        />
      </Head>

      <PostHogProvider client={posthog}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {/* Google Login */}
            <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
              {/* Google Analytics/Tag Manager Script */}
              <noscript>
                <iframe
                  src={
                    process.env.NEXT_PUBLIC_JSW_GEOLOCATION === "sg"
                      ? // PRODUCTION INITIALISATION SCRIPT FOR GTM
                        process.env.NEXT_PUBLIC_JSW_ENV === "prod"
                        ? "https://www.googletagmanager.com/ns.html?id=GTM-PKBTGWBL"
                        : "https://www.googletagmanager.com/ns.html?id=GTM-PKBTGWBL&gtm_auth=Xse8T1yTm8KtToQ_qltS1Q&gtm_preview=env-3&gtm_cookies_win=x"
                      : process?.env?.NEXT_PUBLIC_APP_API_URL ===
                        "https://api-v2.wobbjobs.com"
                      ? "https://www.googletagmanager.com/ns.html?id=GTM-NCZF2HF"
                      : "https://www.googletagmanager.com/ns.html?id=GTM-NCZF2HF&gtm_auth=dOSKDsOH-HMVNVAJ7F5unA&gtm_preview=env-779&gtm_cookies_win=x"
                  }
                  height="0"
                  width="0"
                  style={{ display: "none", visibility: "hidden" }}
                ></iframe>
              </noscript>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <Component {...pageProps} err={err ? err : {}} />
            </GoogleOAuthProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </PostHogProvider>
    </Fragment>
  );
}

export default wrapper.withRedux(MyApp);
